@use "sass:map";
@use "@picocss/pico/scss/pico" as * with (
  $theme-color: "indigo",
  $enable-semantic-container: true,
  $enable-classes: true
);

$background-color_1: var(--pico-primary-background);

html {
  height: 100svh;
}
body {
  height: 100svh;
  > footer {
    /*position: sticky;
		top: 100svh;*/
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $background-color_1;
    padding: 0.5em;
  }
  > header {
    position: sticky;
    top: 0;
    padding: 0;
    background-color: $background-color_1;
    text-align: center;
  }
  > main {
    padding: 0;
  }
}

body > header h2 {
  color: white;
}

body {
  > main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.page-scan button,
.cam-btn button,
button.full {
  width: 100%;
}

.xcam {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.page-shot .cammer {
  text-align: center;
}

.cam-btn {
  padding: 0.5em;
}

.thumb-container {
  display: flex;
  .img_thumb {
    flex-basis: 50%;
    &.sel {
      border: 3px solid green;
      padding: 5px;
      margin: 5px;
      position: relative;
      &::after {
        content: "✔";
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 300%;
        color: green;
      }
    }

    &.nosel {
      border: 3px double #ccc;
      padding: 5px;
      margin: 5px;
    }
  }
}

// img.thumb {
//     height: 60px;
//     border: 1px solid black;
//     padding: 5px;
//     margin: 2px;
// }

.noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#cam-wrapper {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  background-color: black;
  text-align: center;
}

.take-btn {
  display: inline-block;
  position: relative;
  top: 80vh;
  z-index: 100;
  opacity: 0.7;
}

.footer_text {
  text-align: center;
  color: white;
}
